import React from 'react';
import './App.scss';
import CarouselComponent from './components/Carousel/CarouselComponent';
import FirstComponent from './components/FirstComponent/FirstComponent';
import SecondComponent from './components/FinallSecondComponent/FinalSecondComponent';
import './components/utils/Border.css';
import Aquarium from './components/utils/aquarium/Aquarium';
import Navbar from './components/Navbar/Navbar';
function App() {

  return (
    <div className="App">
    </div>
  );
}

export default App;